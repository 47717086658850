import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Chat`}</inlineCode>{` merupakan sebuah interface yang memungkinkan user untuk melakukan percakapan (text, suara atau visual), bisa antara manusia dan/ atau sistem yang diperkaya NLP.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard subTitleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADEklEQVQ4y5VVS0wTURRtCBEhCAs/C40QkJio6MKFRJSA4EZBItGVezAkLExYqBASDT/xFyPho8SFGkOC/CkgIOUbLDFoAhqFFiMgYG0LLe1M2+nQ430PihVoxUlO77yZe889Z17erUKwSbCKDrBoERwwLYkwWbYIymU1nhwK9uN+IDld+N+L1bjJ1ggtgh2S7MLYl2+4XlCOG0VPcLu4EnkFZcihNcNNhqJKZOdXIfdOFfJKniKH1qyG1TKONcIlq413q2lWwW/vSQQdSEJIeDx2RyYgNDwOoRHxCKH7bWHxOHL8GBQ7o6EIPApFwCFewy7GsYGw8c0AohOuIO5iJmKS03H2UhbiLqTjVEo6Ei9nITY1E+fTUnEwJgW7Dp9DwP7TaKIa74Tt/fDfF4uQqEQERyUhMDIRgRFnCAnYToqDKPqHJWIHOQgmxYo9J3jNJoR2/rC+rQ+5xRWob+9DS0c/JfdC2TmAjt5hdPSoeezsVaO9ewj97z4i/+Ez1LX2rhLaNxI2EGEjkZmpm2S3YcGgh8Muet1h1eB71Cp7vBOyl61dg/zeZrPDKctwOCSINrIjCDyKog0OyclzWki9T0Jm+XVTJ2ZnpqHVajExMQ6tRkNxApOTk5iensbU1BQMRiNXzj5HndKH5Xr6Hg1tKlovYXZeh3ndL+j0Bvyk6HQ6IZNimat28HzmxqdC1k3ZNQC20pmdMFplDsGxcoKWXS7Iy8uQVi23vv0XIVluUnZD0Jug+WHE97kFjlndIhYWzdAbFjE1M8dhNlvITY/vXWbduvqH+b1ZdEGQACu5W3/CRXFl57tVQ6hdOymbEDZ3DCIjuxCPqqpRUvoCd8tectwj3C//g5LS53hcrURGYQWaVeqNhO5pYVoS+GEfGR3HhzEPjK7DJw3UhQ8wcvUajJ/HIS6TE8/h4CYV7RJocHCLlMPjX1h9JxOE5DQIfkEw1bdAoLXV8+i5FbLIBqZ32FdAufpXNdDdKoLpqwaCvInCrcLKIjW2kioLU0YDlq89B+yWwZy4i5lF+hsQSJm7Ccv5DZ91cCQL1G0YAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/a478309e969b38b2a8e1b36376fbcf34/d67c7/widget.png",
              "srcSet": ["/static/a478309e969b38b2a8e1b36376fbcf34/7fc1e/widget.png 288w", "/static/a478309e969b38b2a8e1b36376fbcf34/a5df1/widget.png 576w", "/static/a478309e969b38b2a8e1b36376fbcf34/d67c7/widget.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard subTitleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACbklEQVQ4y52Uy0/UUBTGZ+tG3RgXxn9AY4Q9GxcEYzTxSVxi2IrufGz4c/CBD9AIGWLU+FiIiTEmBjfCwBiZofNoex/tvdPyec6ddixBJkCT33ynvXO+nnPuTUsqsmCkNtBxB7FJECoDQff5GqMJXottRhbruJub/6/EP0LFSDeBj5+/Y3j0FsYnJjF2cxIXb9zF1fH7uDJ+D5fG7mDk+m3H2Yzh0Ql8oBzOZY+eYSgj8DX98g1KBwcwdOYaBoYu4+ipczh2+jyOD17AkRMjKB0eROnQwD89cNLl8MUePUMumctf/V3DzNxbPHxexqOZMqaevMKDZ/OOqadzeDy7gOmM2fl3eFF+j0q1Rrlpr+1ScYaRsfSuTYRBG4HfIvV7MTYt/nfpyGyfYf4glBpSKQgpKZZOhVTwQ4mfVR9La+0ePyotrNQCWtcoemwxFDQHYw2SNEUnSYgUKcURjePTUgOvv1ZRXlzBwpcK5hcr+ParSbkx5I6GKoLWGp7nodlsotFouJiV733fdy9I0oQ0gaWXi2wzdjSMYtp+altlrcssZjgO6XgEZBKQCqqOc/q2HBsD2+nAEDbD2K5K2oA/TYXKhsSap1Brqd5x6WtorHVahGdr6fmGr7GaGdbbancV5oZFjWIDX8ZYb2mqkiCtt/XeKiyqjrvtLtclqg3lqFO1Yq+GecztBlQht5rDM9y3oWub1AsitynLNUnt78OwqK5KOi55y56/ixnyOYzN9l3OaYsIVW+3hipy1XTPn92Gcccmwqoz1K79voah0AhCgUDIrhYRXfVaIdYbgYNjztnRkJW/vP2QjM5wsdli+BdT88y5a7W4SgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/9c7d1b3dafea6a4f01ce205a30d5f6e1/d67c7/bot.png",
              "srcSet": ["/static/9c7d1b3dafea6a4f01ce205a30d5f6e1/7fc1e/bot.png 288w", "/static/9c7d1b3dafea6a4f01ce205a30d5f6e1/a5df1/bot.png 576w", "/static/9c7d1b3dafea6a4f01ce205a30d5f6e1/d67c7/bot.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard subTitleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACpUlEQVQ4y5VUTUtUURiebYtaRKv6ARGFbmvTToqIwMhomRi00tqVK39BECEtgiAiCAr6QERQc8BJ0DTLyRZpzvg5eptx0Ptx7jn36+l9z713SptGPfDMO/ec+z7neZ/z3pNxXA8MWygI6UOqAKajYBHSNYYgSC/4AxVHIePc9L0M/1iORBgBHyfyaLnehY6uHrR39qC1/T7abnXjWkc3Wm/ew8UbdxLc1Whp60SOcjiXOWqEpu2Cx+u+EWSONOPc+as4c/YKjp26gONNl3Ci+TKOnmxB5nAzoSmO9F7m0Gmdw4M5aoQsmeUvrRp42z+C56/68Ozle7yg+Iae+4dyGMqOIZubQHZ0PI65cQwMj2JxZYNyw1rZmZ0eKtQbbgDMb7iYW3fw05D0X+BHScAwA7j1PEwnTFvAEYKIJR0ClSBcMl9itWzhw/QyhqeKyH5dwcj0EgYni5hZqJB3Lv7m2EFokQ++7yOKol0a0+cQUcjrJJng+3HOfwlZoR8EOnV+oYDxyc+Y+jKDbdPSc1IpuASZwKVKGirUhKSQR9/AIB48eozeJ0+xsrpWI0yhPE/HPRUGicLFpWXkZ78j/22WFJp6LiXhuC9CXvRIYUq6e3gHIbQFR6nVFAoFFItFwiIMw8DW1pYm480OTGhaFkqlEmENxsY6PGqbgE4zCMKDEaYlKzadGtyo2hoVi9ZlmHjo7/Bx34SshG8Rhkkf/TZ768VtUiMjuFLt0di0aNukqlJBdXMT1WoVlXIZm/T8i2KZwH4yIW/A0F9XI0KVeqTikrh8lfZeAu5Vvq6qpoBdT6GTHIpDh8ItwwcQhVHd1kmne9/N4fbDT3rjhh7atkO7Cg2+HHaD530qdXahjLH8GuU28JAjl5LCtP+FlRySJGVceu3qSuJvKj7LlefA6p0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/ca43b67e115eb9ab4455c66f398220d0/d67c7/product.png",
              "srcSet": ["/static/ca43b67e115eb9ab4455c66f398220d0/7fc1e/product.png 288w", "/static/ca43b67e115eb9ab4455c66f398220d0/a5df1/product.png 576w", "/static/ca43b67e115eb9ab4455c66f398220d0/d67c7/product.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      